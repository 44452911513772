import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import { Theme, SxProps } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';

import DownloadButton from './download-button';
import { fileData } from './utils';

type FileIconProps = {
  file: File | string;
  tooltip?: boolean;
  imageView?: boolean;
  onDownload?: VoidFunction;
  sx?: SxProps<Theme>;
  imgSx?: SxProps<Theme>;
};

export default function FileThumbnail({
  file,
  tooltip,
  imageView,
  onDownload,
  sx,
  imgSx,
}: FileIconProps) {
  const { name = '', path = '', preview = '' } = fileData(file);

  const renderContent = (
    // format === 'image' && imageView ? (
    <Box
      component="img"
      src={preview}
      sx={{
        width: 1,
        height: 1,
        flexShrink: 0,

        objectFit: 'cover',
        ...imgSx,
      }}
    />
  );

  if (tooltip) {
    return (
      <Tooltip title={name}>
        <Stack
          flexShrink={0}
          component="span"
          alignItems="center"
          justifyContent="center"
          sx={{
            width: 'fit-content',
            height: 'inherit',
          }}
        >
          {renderContent}
          {onDownload && <DownloadButton onDownload={onDownload} />}
        </Stack>
      </Tooltip>
    );
  }

  return (
    <>
      {renderContent}
      {onDownload && <DownloadButton onDownload={onDownload} />}
    </>
  );
}
